<template>
    <div>
        <div id="Topology" ref="Topology"></div>
    </div>
</template>

<script>
import OrgChart from '@balkangraph/orgchart.js';
export default {
    name: 'Topology',
    data() {
        return {
            nodes: [
                {id: 1, name: 'Denny Curtis', title: 'CEO', img: 'https://cdn.balkan.app/shared/2.jpg'},
                {id: 2, pid: 1, name: 'Ashley Barnett', title: 'Sales Manager', img: 'https://cdn.balkan.app/shared/3.jpg'},
                {id: 3, pid: 1, name: 'Caden Ellison', title: 'Dev Manager', img: 'https://cdn.balkan.app/shared/4.jpg'},
                {id: 4, pid: 2, name: 'Elliot Patel', title: 'Sales', img: 'https://cdn.balkan.app/shared/5.jpg'},
                {id: 5, pid: 2, name: 'Lynn Hussain', title: 'Sales', img: 'https://cdn.balkan.app/shared/6.jpg'},
                {id: 6, pid: 3, name: 'Tanner May', title: 'Developer', img: 'https://cdn.balkan.app/shared/7.jpg'},
                {id: 7, pid: 3, name: 'Fran Parsons', title: 'Developer', img: 'https://cdn.balkan.app/shared/8.jpg'}
            ],
        };
    },
    methods: {
        oc(domEl, x) {
            this.$client.getBdzTopology().then((res) => {
                let treeData = res.data;
                if (treeData) {
                    x = res.data;
                    x.map((it) => {
                        it.tags = ['normal'];
                    });
                    x[0].tags = ['root'];
                    x[1].tags = ['parentWire'];
                    x[2].tags = ['parentWire'];
                    x[3].tags = ['parentWire'];
                    x[4].tags = ['parentWire'];
                    // x = this.nodes;
                    //拓扑图样式
                    //根节点
                    OrgChart.templates.root = Object.assign({}, OrgChart.templates.ana);
                    OrgChart.templates.root.size = [260, 200];
                    OrgChart.templates.root.node = '<rect x="0" y="0" height="{h}" width="{w}" fill="#2749A5" stroke-width="1" stroke="#aeaeae" rx="5" ry="5"></rect>';
                    OrgChart.templates.root.field_0 = '<text width="230" style="font-size: 18px;" fill="#cfcfcf" x="120" y="30" text-anchor="middle">{val}</text>';
                    OrgChart.templates.root.field_1 = '<text width="230" style="font-size: 14px;" fill="#cfcfcf" x="120" y="70" text-anchor="middle">{val}</text>';
                    OrgChart.templates.root.field_2 = '<text width="230" style="font-size: 14px;" fill="#cfcfcf" x="120" y="110" text-anchor="middle">{val}</text>';
                    OrgChart.templates.root.field_12 = '<text width="230" style="font-size: 14px;" fill="#cfcfcf" x="120" y="150" text-anchor="middle">{val}</text>';

                    //母线
                    OrgChart.templates.parentWire = Object.assign({}, OrgChart.templates.root);
                    OrgChart.templates.parentWire.size = [260, 400];
                    OrgChart.templates.parentWire.field_0 = '<text width="230" style="font-size: 18px;" fill="#cfcfcf" x="120" y="30" text-anchor="middle">{val}</text>';
                    OrgChart.templates.parentWire.field_1 = '<text width="230" style="font-size: 14px;" fill="#cfcfcf" x="120" y="70" text-anchor="middle">{val}</text>';
                    OrgChart.templates.parentWire.field_2 = '<text width="230" style="font-size: 14px;" fill="#cfcfcf" x="120" y="100" text-anchor="middle">{val}</text>';
                    OrgChart.templates.parentWire.field_3 = '<text width="230" style="font-size: 14px;" fill="#cfcfcf" x="120" y="130" text-anchor="middle">{val}</text>';
                    OrgChart.templates.parentWire.field_4 = '<text width="230" style="font-size: 14px;" fill="#cfcfcf" x="120" y="160" text-anchor="middle">{val}</text>';
                    OrgChart.templates.parentWire.field_5 = '<text width="260" style="font-size: 14px;" fill="#cfcfcf" x="120" y="190" text-anchor="middle">{val}</text>';
                    OrgChart.templates.parentWire.field_6 = '<text width="260" style="font-size: 14px;" fill="#cfcfcf" x="120" y="220" text-anchor="middle">{val}</text>';
                    OrgChart.templates.parentWire.field_7 = '<text width="260" style="font-size: 14px;" fill="#cfcfcf" x="120" y="250" text-anchor="middle">{val}</text>';
                    OrgChart.templates.parentWire.field_8 = '<text width="260" style="font-size: 14px;" fill="#cfcfcf" x="120" y="280" text-anchor="middle">{val}</text>';
                    OrgChart.templates.parentWire.field_9 = '<text width="260" style="font-size: 14px;" fill="#cfcfcf" x="120" y="310" text-anchor="middle">{val}</text>';
                    OrgChart.templates.parentWire.field_10 = '<text width="260" style="font-size: 14px;" fill="#cfcfcf" x="120" y="340" text-anchor="middle">{val}</text>';
                    OrgChart.templates.parentWire.field_11 = '<text width="260" style="font-size: 14px;" fill="#cfcfcf" x="120" y="370" text-anchor="middle">{val}</text>';
                    //普通节点
                    OrgChart.templates.myTemplate = Object.assign({}, OrgChart.templates.root);
                    OrgChart.templates.myTemplate.size = [260, 300];
                    OrgChart.templates.myTemplate.field_0 = '<text width="230" style="font-size: 18px;" fill="#cfcfcf" x="120" y="30" text-anchor="middle">{val}</text>';
                    OrgChart.templates.myTemplate.field_1 = '<text width="230" style="font-size: 14px;" fill="#cfcfcf" x="120" y="70" text-anchor="middle">{val}</text>';
                    OrgChart.templates.myTemplate.field_2 = '<text width="230" style="font-size: 14px;" fill="#cfcfcf" x="120" y="100" text-anchor="middle">{val}</text>';
                    OrgChart.templates.myTemplate.field_3 = '<text width="230" style="font-size: 14px;" fill="#cfcfcf" x="120" y="130" text-anchor="middle">{val}</text>';
                    OrgChart.templates.myTemplate.field_4 = '<text width="230" style="font-size: 14px;" fill="#cfcfcf" x="120" y="160" text-anchor="middle">{val}</text>';
                    OrgChart.templates.myTemplate.field_5 = '<text width="260" style="font-size: 14px;" fill="#cfcfcf" x="120" y="190" text-anchor="middle">{val}</text>';
                    OrgChart.templates.myTemplate.field_6 = '<text width="260" style="font-size: 14px;" fill="#cfcfcf" x="120" y="220" text-anchor="middle">{val}</text>';
                    this.chart = new OrgChart(domEl, {
                        // template: 'derek',
                        // template: 'myTemplate',
                        nodes: x,
                        nodeBinding: {
                            field_0: 'name',
                            field_1: 'f1',
                            field_2: 'f2',
                            field_3: 'f3',
                            field_4: 'f4',
                            field_5: 'f5',
                            field_6: 's1',
                            field_7: 's2',
                            field_8: 's3',
                            field_9: 's4',
                            field_10: 's5',
                            field_11: 's6',
                            field_12: 'time'
                        },
                        tags: {
                            root: {
                                template: 'root',
                            },
                            normal: {
                                template: 'myTemplate'
                            },
                            parentWire: {
                                template: 'parentWire'
                            },
                        },
                        nodeMouseClick: null,
                        enableSearch: false
                    });
                }
            });
        }
    },
    mounted() {
        this.oc(this.$refs.Topology, this.nodes);
    }
};
</script>
