<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div class="ui-layout-content--full ui-layout-flex--1" style="margin: 0 20px 20px 20px">
            <ex-search-table-pagination
                ref="searchTable"
                :fetch="$client.queryCurrentData"
                :columns="columns"
                :formOptions="formOptions"
                :searchWhenSortChange="true"
                :paramsMethod="searchParams"
                @current-change="selectHandle"
            >
            </ex-search-table-pagination>
        </div>
    </div>
</template>
<script>
import * as dayjs from 'dayjs';

export default {
    name: 'Bdz',
    data() {
        return {
            selectedRecord: null,
            timeList: [
                /*{name: '变电站', value: 'SUBSTATION'},
                {name: '10KV园卡线3号户外开关站', value: 'RING_MAIN_UNIT'}*/
            ]
        };
    },
    computed: {
        columns() {
            return [
                {
                    prop: 'deviceType',
                    label: '设备类型',
                    formatter: (row, column, cellValue) => {
                        if (cellValue === 'RING_MAIN_UNIT') {
                            return '环网柜';
                        }
                        return '变电站';
                    }
                },
                {
                    prop: 'deviceName',
                    label: '设备名称'
                },
                {
                    prop: 'dataCategory',
                    label: '类别',
                    /*formatter: (row, column, cellValue) => {
                        console.log(cellValue);
                        if (cellValue === '1') {
                            return '遥信';
                        } else if (cellValue === '2') {
                            return '遥测';
                        }
                        return '公共';
                    }*/
                    formatter: (row, column, cellValue) => {
                        switch (cellValue) {
                            case 1:
                                this.dataCategory = '遥信';
                                break;
                            case 2:
                                this.dataCategory = '遥测';
                                break;
                            case 3:
                                this.dataCategory = '公共';
                                break;
                            default:
                                break;
                        }
                        return this.dataCategory;
                    }
                },
                {
                    prop: 'cabinet_group',
                    label: '柜组'
                },
                {
                    prop: 'tagName',
                    label: '标签名称'
                },
                {
                    prop: 'dataValueDouble',
                    label: '浮点数值'
                },
                {
                    prop: 'dataValueBoolean',
                    label: '布尔数值'
                },
                {
                    prop: 'dataValueInt',
                    label: '整形数值'
                },
                {
                    prop: 'dataValueString',
                    label: '字符数值'
                },
                {
                    prop: 'description',
                    label: '描述'
                },
                {
                    prop: 'createTime',
                    formatter: (row, column, cellValue) => {
                        if (cellValue) {
                            let time = dayjs(cellValue).format('YYYY-MM-DD HH:mm');
                            return time;
                        }
                        return '';
                    },
                    label: '创建时间'
                }
            ];
        },
        formOptions() {
            return {
                forms: [
                    {
                        prop: 'begin',
                        itemType: 'datetime',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择开始时间',
                        options: this.timeList,
                        labelKey: 'name',
                        valueKey: 'value'
                    },
                    {
                        prop: 'end',
                        itemType: 'datetime',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择结束时间',
                        options: this.timeList,
                        labelKey: 'name',
                        valueKey: 'value'
                    },
                ]
            };
        }
    },
    methods: {
        selectHandle(val) {
            this.selectedRecord = val;
        },
        searchParams(params) {
            params.deviceId = 1;
            //params.cabinetGroupId = '变电站';
            return params;
        },
        /*init() {
            this.$client.getAllCurrentDataTime('MAIN_SUBSTATION', '变电站').then((data) => {
                if (data && data.data) {
                    let list = data.data;
                    for (let i = 0; i < list.length; i++) {
                        let item = {
                            name: list[i],
                            value: list[i]
                        };
                        this.timeList.push(item);
                    }
                }
            });
        }*/
    },
    mounted() {
        //this.init();
    }
};
</script>
