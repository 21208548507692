<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div style="padding: 20px 0px 0px 20px">
            <el-button type="primary" @click="show(0)">列表</el-button>
            <el-button type="primary" @click="show(1)">拓补图</el-button>
        </div>
        <bdz-table v-if="this.tableShow"></bdz-table>
        <bdz-topology v-if="this.topologyShow"></bdz-topology>
    </div>
</template>
<script>
import BdzTable from './bdzTable';
import BdzTopology from './bdzTopology';
export default {
    name: 'Bdz',
    components: {
        BdzTable,
        BdzTopology,
    },
    data() {
        return {
            tableShow: true,
            topologyShow: false,
        };
    },
    methods: {
        show(type) {
            if (type) {
                this.tableShow = false;
                this.topologyShow = true;
            } else {
                this.tableShow = true;
                this.topologyShow = false;
            }
        },
    },
};
</script>
